.login-button {
  display: flex;
  justify-content: center;
  padding-top: 15px;

  button {
    min-width: 120px;
  }
}

.login-error {
  color: red;
}


.orders-list {
  padding-top: 80px;

  .card-title {
    /*font-weight: 300;*/
    padding: 0 1.25rem;
  }

  .card {
    border: 0;
    box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.2), 0px 2px 3px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 8px;
    overflow: hidden;
    max-width: 650px;
    margin: 25px auto;
  }

  .card-body {
    padding: 1rem 0;
  }

  .order-row-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    &:last-child {
      border-bottom: 0;
    }
  }

  .order-row {
    padding: 10px 20px;
    font-size: 14px;
    align-items: center;
    margin: 0 auto;

    .order-action {
      max-width: 40px;
      padding-right: 0;
      text-align: right;
      font-size: 28px;
      /*color: #cc0022;*/
    }

    .order-amount {
      font-weight: 500;
    }

    > div {
      padding-right: 10px;
      flex: 1;
    }
  }

  .order-status {
    min-width: 110px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    /*align-items: flex-start;*/
    justify-content: center;
  }

  .status-icon {
    font-size: 30px;
    /* flex-grow: 1; */
    text-align: center;
    margin-bottom: 10px;
  }

  .status-text {
    /*font-size: 14px;
    font-weight: 500;*/
    text-align: center;
  }

  .newOrder {
    color: $primary;
  }

  .processingOrder {
    color: $warning;
  }

  .readyOrder {
    color: $success;
  }

  .completedOrder {
    font-weight: 500;
  }
}

.table-details {
  th, td {
    border: 0 !important;
    padding: 0.25rem;
    font-size: 14px;
    word-break: break-word;
  }

  tr > td:first-child {
    font-weight: 500;
  }
}

.table-products {
  th, td {
    font-size: 14px;
    word-break: break-word;
    padding: 0.5rem;
  }
}


@media (max-width: 768px) {
  .orders-list {
    .card {
      margin: 25px 15px;
    }
  }
}
