.checkout {
  padding: 80px 15px 30px;

  .navbar {
    padding: 0.5rem 0;

  }

  .product-summary {
    padding: 13px 0;
    border-bottom: 1px solid rgba(0,0,0,.06);
    font-weight: 300;

    &:last-child {
      border-bottom: none;
    }

    .qty {
      font-weight: 500;
      min-width: 40px;
    }
    .name {
      flex-grow: 1;
    }
    .price {
      min-width: 100px;
      text-align: right;

      > span {
        padding-right: 5px;
        display: block;

        &.old-price {
          text-decoration: line-through;
        }

        &.new-price {
          color: #e61d1f;
        }
      }
    }

    .add-to-cart {
      display: none;
    }

    .extras-title {
      font-size: 14px;
      color: $text-muted;
    }
  }


  .add-to-cart button {
    font-size: 18px;
    padding: 0 4px;
    color: #cc0022;
    /* border: 1px solid; */
    /* border-radius: 50%; */
    width: 28px;
    /* text-align: center; */
    /* height: 32px; */
    margin-top: 5px;
    margin-right: 10px;
    box-shadow: none !important;

    &:last-child {
      margin-right: 0;
    }
  }

  .checkout-card {

  }


  .card {
    border: 0;
    box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.2), 0px 2px 3px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 8px;
    overflow: hidden;

    margin: 0 0 25px 0;
  }

  .card-body {
    /*padding: 0;*/
  }

  .card-title {
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-weight: 300;
    /*border-bottom: 1px solid #f2f2f2;*/

    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 15px 0;
  }

  .card-content {
    color: #333;
  }
}

.checkout-button {
  margin: 20px auto;
  max-width: 400px;

  .btn {
    width: 100%;
    font-size: 22px;
    padding: 10px;
    border-radius: 20px;
  }
}

.confirmation-popup {

  h3 {
    font-weight: 300;
    margin-bottom: 35px;
  }

  .loading-note {
    margin: 35px 0 15px;
    color: #656565;
  }

  .offline-note {
    margin: 15px 0 25px;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    font-family: "Open Sans";
  }

  .modal-body {
    padding: 2rem;
    text-align: center;
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
    border-width: 0.15em;
  }
}

.paypal-buttons {
  max-width: 370px;
  margin: auto;
}

.StripeElement {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  max-width: 240px;
}

@media (max-width: 576px) {
  .confirmation-popup {
    .modal-body {
      padding: 2rem 1rem;
    }
  }
}
