body {
  margin: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.form-control::placeholder {
  color: #afafaf;
}
.form-control:focus {
  outline: 0;
  box-shadow: none;
}


.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}

.header {
  overflow-x: hidden;
  position: fixed;
  z-index: 102;
  left: 0;
  right: 0;
  background: #f2f2f2;
}

.header-row {
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: left;
  align-items: center;
}

.header .header-top {
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;

  text-align: left;
  margin-top: 0;
  position: relative;
  padding: 10px 1rem;
  min-height: unset;
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.header .logo {
  /*position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;*/
  flex-grow: 1;
}
.header .logo img {
  max-height: 70px;
  margin: 0;
  padding: 10px 0 10px 10px;
}

.header-top {
  color: #ffffff;
}

.header-top h1 {
  font-size: 42px;
  /* padding-top: 32vh; */
  margin: 0 20px 0 30px;
  font-family: 'Gin-Regular', sans-serif;
  font-weight: 400;
}

.header-top h2 {
  font-size: 32px;
}
.header-content {
  height: 40vh;
  vertical-align: middle;
  margin-top: 10vh;
}

.header .social-icons {
  /*position: absolute;
  top: 22px;
  right: 30px;*/
  z-index: 999;
  /*background: #fff;*/
  padding: 0 10px;
}

.social-icons > a {
  width: 40px;
  /*height: 40px;*/
  color: #333333;
  font-size: 24px;
  display: inline-block;
  background-position: 0 0;
  background-repeat: no-repeat;
  text-decoration: none !important;
  text-align: center;
}
.social-icons > a.instagram-icon {
  background-position: -44px 0;
}
.social-icons > a.email-icon {
  background-position: -88px 0;
}


.order-menu {
  /*padding: 0 -15px;*/
  padding-top: 170px;
  padding-bottom: 70px;
}



.menu-item {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 1px solid #b1b1b1;
  border-radius: 5px;

  padding: 7px 15px;

  position: relative;
  font-size: 0.875rem;
  /* max-width: 264px; */
  /* min-width: 72px; */
  box-sizing: border-box;
  /* min-height: 48px; */
  text-align: center;
  flex-shrink: 0;

  font-weight: 500;
  /* line-height: 1.75; */
  white-space: normal;
  letter-spacing: 0.02857em;

  margin: 0 15px 0 0;
  color: #333;
}
.menu-item-wrapper {

}
.menu-item-wrapper.active,
.menu-item-wrapper:focus {
  outline: none;
}
.menu-item.active {
  color: #cc0022;
  border-color: #cc0022;
  /*padding-bottom: 9px;
  border-bottom-width: 2px;*/
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}


@media (max-width: 990px) {
  .header-top h1 {
    font-size: 24px;
    margin: 0;
    margin-left: 15px;
    display: none;
  }


}

@media (min-width: 576px) {
  /*.container, .container-sm {
   max-width: 100%;
  }*/
}


.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.animatable-enter {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translateX(20px);
  opacity: 0;
  display: block;
}

.animatable-enter-active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translateX(0);
  opacity: 1;
  will-change: all;
  transition: all 0.3s ease;
  display: block;
}

.animatable-exit {
  display: none;
}

.animatable-exit-active {
  display: none;
}

/*.animatable-exit {
  opacity: 1;
  transform: translateX(0);
}
.animatable-exit-active {
  opacity: 0;
  transform: translateX(-110%);
  will-change: all;
  transition: all 0.2s ease;
}*/

.slideUp-enter {
  transform: translateY(-20px);
  opacity: 0;
}

.slideUp-enter-active {
  transform: translateY(0);
  opacity: 1;
  will-change: all;
  transition: all .3s ease;
}

.slideUp-exit {
  opacity: 1;
}
.slideUp-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 200ms;
}


.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.drawer-content-wrapper {
  padding: 35px 10px 10px;
  a.nav-link {
    color: #333;
    /*font-weight: 500;*/
    padding: 0.7rem 1rem;
    /*text-transform: uppercase;*/
    font-size: 15px;

    i {
      text-align: center;
      font-size: 18px;
      margin-right: 15px;
    }
  }

  .footer-items {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #e8e8e8;
    padding-top: 10px;

    a.nav-link {
      display: inline-block;
      text-align: center;
      width: 80px;
      > i {
        font-size: 26px;
        display: inline-block;
        margin: 0;
      }
    }
  }
}



.page-loading {
  text-align: center;
  position: fixed;
  z-index: 5;
  background: #f2f2f2;
  left: 0;
  right: 0;
  bottom: 0;
  top: 60px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: all .3s ease;
  opacity: 1;
  transform: translate(0,0);

  &.not-show {
    /*background: transparent;
    opacity: 0;
    transform: translate(+110%, 0);*/
    display: none;
  }

  .spinner-border {
    width: 4rem;
    height: 4rem;
    margin-top: 30vh;
  }
}

.tooltip-animated {
  font-size: 14px;
  display: block;
  margin: 7px 10px 0 0;
  float: left;
  vertical-align: middle;
  /*color: $primary;*/
  --animate-delay: 2s;
  animation-delay: 2s;
}

.btn-big-action {
  width: 100%;
  font-size: 22px;
  padding: 10px;
  border-radius: 20px;
}

.card {
  border: 0;
  box-shadow: 0px 0px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: hidden;
  margin: 0 0 25px 0;
}

.loading-circle-container {
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  /* text-align: center; */
  /* vertical-align: middle; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Boxes */
.content-semibox {
  max-width: 650px;
  margin: 0 auto;

  .row-form {
    width: 100%;
  }
}

