.order-menu {

  .products-nav {

    /*background-color: #fff;

    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);*/

    position: fixed;
    top: 126px;
    z-index: 3;
    background-color: #f2f2f2;
    left: 0;
    right: 0;
    padding-bottom: 10px;
  }
  .next-arrow {

    display: block;
    /* float: right; */
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    bottom: 0;
    align-items: center;
    vertical-align: middle;
    padding: 8px 5px 0;
  }

  .category-card {
    margin: 25px 15px;

    &.featured {
      /*border: 1px solid #eb0000;*/

      .card {
        box-shadow: 0px 0px 2px -1px rgba(255, 0, 0, 0.52), 0px 2px 3px 0px rgba(232, 215, 215, 0.55), 0px 1px 3px 0px rgba(255, 0, 0, 0.51);
      }

      .card-title {
        padding-left: 45px;
        font-weight: bold;
        color: #e61d1f;
      }
    }

    .card {
      border: 0;
      box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.2), 0px 2px 3px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
      border-radius: 8px;
      overflow: hidden;
    }

    .card-body {
      padding: 0;
    }

    .card-title {
      text-align: left;
      font-family: "Roboto", sans-serif;
      color: #333;
      font-weight: 300;
      margin: 0 10px;
      /*border-bottom: 1px solid #f2f2f2;*/

      font-size: 20px;
      padding: 15px 0;
      text-transform: uppercase;

    }

    .card-content {
      color: #333;
    }

    img.new-label {
      position: absolute;
      top: 0;
      left: 0;
      height: 70px;
      z-index: 2;
    }


    .product-row {
      position: relative;
      font-size: 15px;
      border-bottom: 1px solid #e4e4e4;
    }

    .product-row:last-child {
      border-bottom: none;
    }

    .row-disabled {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      padding-top: 5px;
      background: rgba(240, 240, 240, 0.5);
      z-index: 1;
      color: #000;
      vertical-align: middle;
    }

    .product-details {
      display: flex;
      align-items: flex-start;
      padding: 25px 10px 20px;
      font-weight: 500;
    }

    .product-meta {
      padding-right: 40px;
      flex-grow: 1;
    }

    .product-description {
      font-weight: 400;
      color: #828282;
      font-size: 14px;
    }

    .product-price {
      text-align: right;
      padding-right: 5px;
      min-height: 22px;
    }

    .product-action {
      min-width: 75px;
    }

    .add-to-cart {
      text-align: right;
    }

    .add-to-cart button {
      font-size: 22px;
      padding: 0 4px;
      color: #cc0022;
      /* border: 1px solid; */
      /* border-radius: 50%; */
      width: 32px;
      /* text-align: center; */
      /* height: 32px; */
      margin-top: 5px;
      box-shadow: none !important;
      margin-right: 5px;
      line-height: 1;

      &:last-child {
        margin-right: 0;
      }
    }

    .add-to-cart button i.fa-stack-1x {
      font-size: 18px;
    }

    .add-to-cart button i.fa-stack-2x {
      font-size: 22px;
    }


  }

  .product-manage {
    padding: 10px 1rem;
    background-color: #f7f7f7;
    border-top: 1px solid #e4e4e4;

    .qty {
      font-weight: 600;
      min-width: 30px;
    }

    .name {
      margin-left: 10px;
      flex-grow: 1;
    }

    .price {
      margin: 0 10px;
      min-width: 45px;
      text-align: right;
    }

    .remove {
      /*width: 40px;*/
      font-size: 18px;
      text-align: right;
      cursor: pointer;
      padding: 0 0 0 10px;

      > a {
        margin-top: -3px;
        display: block;
        padding: 0 5px;
      }
    }

    .extras-title {
      font-size: 13px;
      color: $text-muted;
    }
  }

  .product-note {
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 20px;

    label {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
      color: #a2a2a2;
    }

    input {
      max-width: 450px;
      font-size: 13px;
      background: transparent;
      border: 0;
      padding: 0.25rem 0;
      border-bottom: 1px solid #dedede;
      border-radius: 0;
      color: #757575;
    }

    /*textarea.form-control.form-control-sm {
      !* margin-left: 40px; *!
      font-size: 14px;
      line-height: 1;
    }*/
  }


  .product-search {
    position: fixed;
    top: 69px;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: #f2f2f2;
    padding-bottom: 15px;

    > div {
      /* padding: 10px; */
      margin: 0 auto;
      padding: 0 10px;
    }

    input {
      border-radius: 10px;
      /*font-size: 14px;*/
      padding: 0.5rem 1rem;
      box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
      height: calc(1.5em + 1rem + 2px);
    }
  }

  .product-variants {
    > button {
      margin-right: 25px;
    }
  }

  .menu-loading {
    text-align: center;
    position: fixed;
    z-index: 5;
    background: #f2f2f2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 60px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transition: all .3s ease;
    opacity: 1;
    transform: translate(0,0);

    &.not-show {
      /*background: transparent;
      opacity: 0;
      transform: translate(+110%, 0);*/
      display: none;
    }

    .spinner-border {
      width: 4rem;
      height: 4rem;
      margin-top: 30vh;
    }
  }

  .special-price {
    text-align: right;
    padding-right: 5px;
    margin-top: 5px;
    font-weight: 300;
  }

  .pickup-icon {
    fill: currentColor;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    margin-top: -2px;
  }

  .promo-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.35rem;
    font-weight: 500;
  }

  .promo-text:before, .promo-text:after {
    /*display: inline-block;*/
    content: ' ';
    background-image: url('https://campfire.mx/images/heading-icon.png');
    background-repeat: no-repeat;
    width: 30px;
    height: 20px;
    margin: 0 10px;

    display: none;
  }

  .promo-text:after {
    transform: rotate(180deg);
  }

  @media (max-width: 768px) {
    .promo-text {
      font-size: 1.25rem;
    }
    .promo-text:before, .promo-text:after {
      display: none;
    }
  }
}

.product-variant-add {
  max-width: 330px;
  margin: 0.5rem auto;
  /*text-align: center;*/

  .variant-buttons {
    font-size: 16px;

    h4 {
      margin-bottom: 20px;
    }

    h6 {
      margin-bottom: 10px;
    }

    > button {
      margin-bottom: 20px;
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

.extras-list {
  margin-bottom: 25px;


  .custom-control {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2rem;
  }

  .custom-control-label:before {
    width: 1.25rem;
    height: 1.25rem;
    left: -2rem;
  }

  .custom-control-label:after {
    top: 0.125rem;
    left: -2.125rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .custom-control-label > span {
    color: #cc0023;
    padding-left: 10px;
  }
}

.checkout-cta {
  position: fixed;
  bottom: 20px;
  right: auto;
  width: 100%;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%,100px);
  padding: 0 20px;
  opacity: 0;

  &.cta-show {
    opacity: 1;
    transform: translate(-50%,0);
    will-change: all;
    transition: all .2s ease;
  }

  .btn.btn-cart {
    width: 100%;
    font-size: 22px;
    padding: 10px;
    border-radius: 20px;
    /*max-width: 400px;
    margin: 0 auto;*/
  }
}
