@import "variables";
//@import '~/bootstrap/scss/bootstrap.scss';
@import "bootstrap/scss/bootstrap.scss";


//@import '../../node_modules/react-datepicker/dist/react-datepicker.css';
//@import '../../node_modules/bootstrap-daterangepicker/daterangepicker.css';

//@import "../../node_modules/react-transition-group/dist/";

@import 'helper';

@import 'module/m-checkmark-loader';

@import 'layout/l-custom';

@import 'page/p-order-form';
@import 'page/p-checkout';
@import 'page/p-account';
@import 'page/p-payment';
@import 'page/p-dashboard';

