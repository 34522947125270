.dashboard-content {
  padding: 110px 15px 30px;

  .card {
    position: relative;
  }

  .menu-buttons {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;

    .menu-button {
      /* flex-grow: 1; */
      margin-bottom: 30px;
      width: 300px;

      > .btn {
        padding: 30px 15px;
        /*display: block;*/
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 0;
        box-shadow: 0px 0px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 8px;

        &:not(:disabled):not(.disabled):active,
        &:hover {
          color: inherit;
        }

        i {
          margin-right: 15px;
          font-size: 24px;
        }
      }
    }
  }
}
